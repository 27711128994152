







import { Component, Vue } from 'vue-property-decorator'

import StudiesTable from '@/partials/tables/Events/Studies/index.vue'
import MyStudiesTable from '@/partials/tables/Events/Studies/myStudies.vue'

@Component({
  components: {
    MyStudiesTable,
    StudiesTable
  }
})
export default class EventStudies extends Vue {

}
