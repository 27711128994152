



































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import View16 from '@carbon/icons-vue/es/view/16'
import isEmpty from 'lodash/isEmpty'

import PermissionsMixin from '@/mixins/permissionsMixin'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    GenericModalDelete,
    View16,
    TrashCan16,
    Edit16
  }
})
export default class EventMyStudiesTable extends Mixins(PermissionsMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  studies = []
  filteredData = []
  deleteModalIsOpened = false
  isAcceptingStudies = false
  selectedStudy = {
    id: '',
    title: ''
  }

  created () {
    axios.get(`/event/${this.eventId}`)
      .then(response => {
        this.isAcceptingStudies = camelCaseKeys(response.data.data, { deep: true }).acceptingStudies
      })

    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/study/my-studies`)
      .then(response => {
        this.studies = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  tagKind (status: 'accepted' | 'rejected' | 'received' | 'reviewing') {
    const dict = {
      received: ['warm-gray', 'Recebido'],
      reviewing: ['cyan', 'Em revisão'],
      accepted: ['green', 'Aceito'],
      rejected: ['red', 'Rejeitado']
    }

    return dict[status]
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Trabalho Científico foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteUrl () {
    return isEmpty(this.selectedStudy) ? '' : `/event/${this.eventId}/study/${this.selectedStudy.id}`
  }
}
