










































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import Report16 from '@carbon/icons-vue/es/report/16'
import View16 from '@carbon/icons-vue/es/view/16'
import isEmpty from 'lodash/isEmpty'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    GenericModalDelete,
    View16,
    Report16,
    TrashCan16,
    Ticket16,
    Edit16,
    TablePagination
  }
})
export default class EventStudiesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  statusFilter = 'accepted'
  studies = []
  filteredData = []
  totalStudies = 0
  deleteModalIsOpened = false
  selectedStudy = {
    id: '',
    title: ''
  }

  created () {
    const statusQueryString = this.$route.query.status
    if (statusQueryString) {
      this.statusFilter = statusQueryString as string
    }

    if (this.hasEventPermission(this.eventId, this.permissions.EVENTSTUDYREVIEW.CREATE)) {
      this.statusFilter = 'received'
    } else {
      this.statusFilter = 'accepted'
    }
  }

  fetch (page = 1, perPage = 20) {
    const perPageSnake = snakecaseKeys({ perPage })

    axios.get(`/event/${this.eventId}/study`, {
      params: {
        status: this.statusFilter,
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.studies = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalStudies = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Trabalho Científico foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }

  tagKind (status: 'accepted' | 'rejected' | 'received' | 'reviewing') {
    const dict = {
      received: ['warm-gray', 'Recebido'],
      reviewing: ['cyan', 'Em revisão'],
      accepted: ['green', 'Aceito'],
      rejected: ['red', 'Rejeitado']
    }

    return dict[status]
  }

  @Watch('statusFilter')
  onStatusFilterChange (value: string) {
    if (this.$route.query.status !== value) {
      this.$router.push({ query: { status: value !== '' ? value : undefined } })
    }
  }

  @Watch('$route')
  onRouteChange () {
    this.fetch()
  }

  get deleteUrl () {
    return isEmpty(this.selectedStudy) ? '' : `/event/${this.eventId}/study/${this.selectedStudy.id}`
  }
}
